import './App.css';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import timelineData from "./timeline.json";
import imageData from "./imagedata.json";
import Moment from 'moment';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Box from "@material-ui/core/Box";
import ImageList from "@material-ui/core/ImageList";
import ImageListItem from "@material-ui/core/ImageListItem";
import Fade from "@material-ui/core/Fade";
import React, {useEffect, useState, useRef} from 'react';
import {useReactToPrint} from 'react-to-print';
import PrintIcon from '@material-ui/icons/Print';
import Fab from "@material-ui/core/Fab";
import responsiveFontSizes from "@material-ui/core/styles/responsiveFontSizes";
import ThemeProvider from "@material-ui/core/styles/ThemeProvider";
import createMuiTheme from "@material-ui/core/styles/createMuiTheme";

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100vh'
    },
    imagegrid: {
        /*  position: 'fixed',*/
        '& li': {
            display: 'list-item',
        }
    },
    paper: {
        padding:theme.spacing(1),
        margin: theme.spacing(0, 4, 16, 4),
    },
    paperm: {
        padding:theme.spacing(1),
        margin: theme.spacing(8, 4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    paperplane: {
        padding:theme.spacing(1),
        margin: theme.spacing(8, 4),
    },
    papern: {
        padding:theme.spacing(1),
        margin: theme.spacing(1, 4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    }
}));
const elpased = () => {
    let a = Moment();
    let b = Moment([2019, 9, 6]);
    let diffDuration = Moment.duration(a.diff(b));
    return diffDuration.years() + ' years ' + diffDuration.months() + ' months ' + diffDuration.days() + ' days';
};

const elpasedMonths = () => {
    let a = Moment();
    let b = Moment([2019, 9, 6]);
    let diffDuration = Moment.duration(a.diff(b));
    return Math.round(diffDuration.asMonths()) + ' Months'
};

function createData(expense, amount) {
    return {expense, amount};
}

const rows = [
    createData('Visa application fee charged by department of home affairs', 7715),
    createData('Migration Agent fee', 1650),
    createData('Medicals fee', 338.23),
    createData('Biometrics fee', 50.69),
    createData('Police Australian federal police clearance  fee', 42),
    createData('Police Kenya clearance  fee', 12.68),
    createData('Marriage celibate fee', 660),
    createData("25 May 2021 - Marion's COVID-19 Test fee (She tested negative)", 102),
];

function subtotal(items) {
    return items.map(({amount}) => amount).reduce((sum, i) => sum + i, 0);
}

function ccyFormat(num) {
    return `${num.toFixed(2)}`;
}

const invoiceSubtotal = subtotal(rows);
const totalrowstyle = {
    borderTop: 'black 3px solid',
};


function transTime() {
    return `${Math.floor(Math.random() * 7000) + 3000}ms`;
}

function shuffleAndGrowArray(array) {

    let arrayDup = [];
    for (var i = 0; i < array.length; ++i) {
        arrayDup.push(array[i]);
        arrayDup.push(array[i]);
        arrayDup.push(array[i]);
        arrayDup.push(array[i]);

    }

    array = arrayDup;


    for (let i = array.length - 1; i > 0; i--) {
        let j = Math.floor(Math.random() * (i + 1));
        let temp = array[i];
        array[i] = array[j];
        array[j] = temp;
    }

    return array;
}

const imagedata = shuffleAndGrowArray(imageData);


function App() {
    let theme = createMuiTheme();
    theme = responsiveFontSizes(theme);
    const classes = useStyles();

    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        documentTitle :"marion_and_tunga_visa_application_timeline",
        content: () => componentRef.current,
    });

    const [trans, setTrans] = useState(false);
    useEffect(() => {
        const timer = setTimeout(() => {
            setTrans(true);
        }, 5000);
        return () => clearTimeout(timer);
    }, []);


    return (

        <ThemeProvider theme={theme}>
            <Fab variant="extended"
                 color="primary"
                 size="large"
                 className={classes.button}
                 style={{position: 'fixed', left: '10px', bottom: '20px', zIndex: '1000'}} onClick={handlePrint}>
                <PrintIcon className={classes.extendedIcon}/>&nbsp;Print this out!
            </Fab>
            <Grid container component="main" className={classes.root}>


                <CssBaseline/>
                <Grid item xs={12} sm={12} md={6} component={Paper} elevation={6} square ref={componentRef}>

                    <Paper className={classes.paperplane}>
                        <Typography variant="h4" component="h1">Marion and Tunga Visa Application Timeline</Typography>
                        Visa Type: Prospective marriage visa, subclass 300 <br/>
                        Application ID: 125630658 <br/>
                        Visa Grant Number: 1039500001561 <br/> <br/>
                        Last applied exemption Transaction reference number: TER0373493 <br/>

                        <br/>
                        <Typography variant="h5">We meet in 2016 and over the years our love grew strong.
                            We applied for this visa on <strong>September 6, 2019</strong>, and the last
                            communication we received from the Department of
                            Immigration and Home Affairs was on <strong>February 20, 2020</strong>.
                        </Typography>
                        <br/>
                    </Paper>
                    <Paper className={classes.paperm}>
                        <Typography variant="h5" component="h1">
                            <strong>Visa Cost (so far)</strong>
                        </Typography>
                        <TableContainer component={Paper}>
                            <Table className={classes.table} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Expense</TableCell>
                                        <TableCell align="right">Amount</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {rows.map((row) => (
                                        <TableRow key={row.expense}>
                                            <TableCell component="th" scope="row">{row.expense}</TableCell>
                                            <TableCell align="right">{row.amount}</TableCell>
                                        </TableRow>
                                    ))}
                                    <TableRow style={totalrowstyle}>
                                        <TableCell colSpan={1}>Total Cost</TableCell>
                                        <TableCell align="right">
                                            <Typography color="secondary">
                                                <strong>{ccyFormat(invoiceSubtotal)} </strong>
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <Typography variant="caption" display="block" gutterBottom>
                            <i>*all costs shown in Austrlian dollars,*some costs converted to Australian dolars from
                                Kenyan
                                Shilling</i>
                        </Typography>
                    </Paper>

                    <Paper className={classes.paperm}>
                        <Typography variant="h5" component="h1">
                            <strong>Time Since Application</strong>
                        </Typography>
                        <Typography variant="h5" component="h1">
                            {elpased()}
                        </Typography>
                        <Typography variant="h5" component="h1">
                            ({elpasedMonths()})
                        </Typography>
                    </Paper>
                    <div className={classes.paperm}>
                        <Timeline align="left">
                            {timelineData.map((data, key) => {
                                return (
                                    <TimelineItem>
                                        <TimelineOppositeContent>
                                            <Box mt={-0.7}>
                                                <Typography variant="h6"><strong>{data.date}</strong></Typography>
                                            </Box>

                                        </TimelineOppositeContent>
                                        <TimelineSeparator>
                                            <TimelineDot color={data.color}/>
                                            <TimelineConnector/>
                                        </TimelineSeparator>
                                        <TimelineContent>
                                            <Paper className={classes.paper}>
                                                <Typography variant="h6">{data.title}</Typography>
                                                <Box pt={0.3}>
                                                    <Typography>
                                                 <span
                                                     dangerouslySetInnerHTML={{
                                                         __html: data.event
                                                     }}/>
                                                    </Typography>
                                                </Box>
                                            </Paper>
                                        </TimelineContent>
                                    </TimelineItem>
                                );
                            })}
                        </Timeline>
                        <Paper className={classes.papern}>
                            <br/>
                            <Typography>
                                Owing to the unpredictable visa processing timeline and time spent apart, our mental
                                health has deteriorated, causing unimaginable stress, depression, and anxiety for both
                                of us. Also as a result of Covid-19 Marion lost her work in the travel industry, which
                                has put added strain on our mental health, despite the fact that we offer emotional
                                support to one another. We are experiencing serious financial pressure as a result of
                                Not being in the same country, we have to pay double the rent and utility bills.
                            </Typography>
                            <br/>
                            <Typography>
                                We want to start a family soon, to buy a home, and to settle down as soon as possible
                                but the unpredictable visa processing time has made it difficult to prepare for the future,
                                it has left us in a state of limbo. When you are separated from your
                                partner for an extended period of time, it induces depression and is mentally
                                exhausting. When there is so much uncertainty, it is difficult to prepare for the
                                future.
                            </Typography>
                            <br/>
                        </Paper>
                        <Typography variant="h5" component="h1">
                            <strong>Time Since Application</strong>
                        </Typography>
                        <Typography variant="h5" component="h1">
                            {elpased()}
                        </Typography>
                        <Typography variant="h5" component="h1">
                            ({elpasedMonths()})
                        </Typography>
                        <Typography variant="h5" component="h1">
                            <strong><a href='mailto:tungaandmarion@gmail.com'>tungaandmarion@gmail.com</a></strong>
                        </Typography>
                        <Typography variant="h5" component="h1">
                            <strong><a href='https://marriage-visa-timeline.herokuapp.com'>https://marriage-visa-timeline.herokuapp.com/</a></strong>
                        </Typography>
                    </div>
                </Grid>
                <Grid item xs={false} sm={12} md={6}>
                    <ImageList variant="masonry" cols={4} gap={2} className={classes.imagegrid}>
                        {imagedata.map((tile) => (
                            <Fade in={true}  {...({timeout: 7000})} style={{transitionDelay: transTime()}}>
                                <ImageListItem key={tile.img}>

                                    <img
                                        srcSet={`${tile.img}?w=161&fit=crop&auto=format 1x,${tile.img}?w=161&fit=crop&auto=format&dpr=2 2x`}
                                        alt={tile.title}
                                        loading="lazy"
                                    />

                                </ImageListItem>
                            </Fade>
                        ))}
                    </ImageList>
                </Grid>
            </Grid>
        </ThemeProvider>
    );
}

export default App;
